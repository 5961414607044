var DocumentPreviewMessage;
(function (DocumentPreviewMessage) {
    DocumentPreviewMessage["NoContent"] = "The document that is associated with this item uses a file format that is not currently supported by previewing. The associated document can therefore not be previewed.";
    DocumentPreviewMessage["MetadataOnly"] = "This item appears to have a document available in the repository but it has not yet been added to EncompaaS.";
    DocumentPreviewMessage["IsRetrievingContent"] = "Capturing content";
    DocumentPreviewMessage["FailedRetrieval"] = "An issue was encountered retrieving the document.";
    DocumentPreviewMessage["LongRetrieval"] = "The retrieval is taking longer than expected.  Please return to this item later to see the retrieved document.";
    DocumentPreviewMessage["Empty"] = "";
})(DocumentPreviewMessage || (DocumentPreviewMessage = {}));
var CaptureContentMessage;
(function (CaptureContentMessage) {
    CaptureContentMessage["MainMessage"] = "Requests the document from the repository and adds it to the item. Once added, this document will be available for future preview requests. This option may take some time to retrieve the document.";
    CaptureContentMessage["Title"] = "Capture Content";
    CaptureContentMessage["ButtonTitle"] = "Capture";
})(CaptureContentMessage || (CaptureContentMessage = {}));
var OpenItemMessage;
(function (OpenItemMessage) {
    OpenItemMessage["MainMessage"] = "Opens a new browser tab and navigates to the item in the repository. This option will fail if you do not have access to the repository or the item.";
    OpenItemMessage["Title"] = "Open Item";
    OpenItemMessage["ButtonTitle"] = "Open from repository";
})(OpenItemMessage || (OpenItemMessage = {}));

export { CaptureContentMessage, DocumentPreviewMessage, OpenItemMessage };
